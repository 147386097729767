import React from 'react';
import styled from 'styled-components';
import { graphql, Link, useStaticQuery } from 'gatsby';

import { useAppDispatch } from '../../types/hooks';
import { setIsMenuOpen } from '../../slices/AppSlice';

import SocialNetworks from './SocialNetworks';

const StyledMenuComponent = styled.nav`
  position: fixed;
  z-index: 2;
  width: 100vw;
  height: calc(90vh - 16px);
  left: 0;
  top: 72px;
  padding: 16px 0;
  border-top: 4px solid var(--rojo-principal);
  background-color: white;

  @media (min-width: 576px) {
    background-color: transparent;
    border-top: none;
  }

  .desktop-menu-container {
    @media (min-width: 576px) {
      max-width: 1100px;
      margin: 0 auto;
      background-color: white;
      width: 20rem;
      transform: translateX(22.5rem);
      position: relative;
      padding: 32px;
      top: -14.4px;
      border: 1px solid var(--border-gray);
      border-top: none;
      z-index: 5;
    }

    .top-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 16px 8px;

      h2 {
        font-family: 'TradeGothic Gold18';
        color: var(--rojo-principal);
        text-transform: uppercase;
        font-size: 1.9rem;
      }

      button {
        display: flex;
        align-items: center;
        font-family: 'TradeGothicN2';
        margin-right: 0;

        img {
          width: 32px;
          height: 32px;
        }
      }
    }

    .social-networks {
      border-bottom: 1px solid var(--border-gray);

      @media (min-width: 576px) {
        display: none;
      }
    }

    ul {
      max-height: 66vh;
      overflow-y: auto;

      li {
        font-family: 'TradeGothicN2';
        text-transform: uppercase;
        border-bottom: 1px solid var(--border-gray);
        padding: 12px 16px;
        list-style: none;

        a {
          color: black;
          text-decoration: none;
        }
      }
    }
  }
`;

const Menu = () => {
  const { wpMenu } = useStaticQuery(graphql`
    {
      wpMenu(locations: { eq: GATSBY_HEADER_MENU }) {
        name
        menuItems {
          nodes {
            id
            label
            url
          }
        }
      }
    }
  `);

  const dispatch = useAppDispatch();

  if (!wpMenu?.menuItems?.nodes || wpMenu.menuItems.nodes === 0) return null;

  return (
    <StyledMenuComponent>
      <div className="desktop-menu-container">
        <div className="top-menu">
          <h2>Menú</h2>
        </div>
        <div className="social-networks">
          <SocialNetworks />
        </div>
        <ul>
          {wpMenu.menuItems.nodes.map((menuItem: any) => {
            const url = menuItem.url.replace(process.env.GATSBY_URL_TO_SHARE, '/');

            return (
              <li key={menuItem.id}>
                <Link
                  to={url}
                  onClick={() => {
                    dispatch(setIsMenuOpen(false));
                  }}
                >
                  {menuItem.label}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </StyledMenuComponent>
  );
};

export default Menu;
