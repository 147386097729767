import React from 'react';
import styled from 'styled-components';

import FacebookIcon from '../../images/fb-menu-icon.svg';
import TwitterIcon from '../../images/tw-menu-icon.svg';
import InstagramIcon from '../../images/ig-menu-icon.svg';
import MailIcon from '../../images/mail-menu-icon.svg';
import WhatsappIcon from '../../images/wp-menu-icon.svg';
import SpotifyIcon from '../../images/spotify-menu-icon.svg';
import YoutubeIcon from '../../images/youtube.svg';

const StyledSocialNetworkContainer = styled.div`
  display: flex;
  margin: 16px 0 8px;

  a {
    flex: 1;
    text-align: center;

    img {
      width: 22.4px;
      height: 22.4px;
    }
  }
`;

const SocialNetworks = () => {
  return (
    <StyledSocialNetworkContainer>
      <a href="https://www.facebook.com/m24radio" rel="noreferrer" target="_blank">
        <img alt="Icono de Facebook" src={FacebookIcon} />
      </a>
      <a href="https://twitter.com/M24radio" rel="noreferrer" target="_blank">
        <img alt="Icono de Twitter" src={TwitterIcon} />
      </a>
      <a href="https://www.instagram.com/m24radio/" rel="noreferrer" target="_blank">
        <img alt="Icono de Instagram" src={InstagramIcon} />
      </a>
      <a
        href="https://open.spotify.com/show/536Sln8TZz3Cx3oEevG3ig?si=imbPdCukQba_UUcwcP7CNw"
        rel="noreferrer"
        target="_blank"
      >
        <img alt="Icono de Spotify" src={SpotifyIcon} />
      </a>
      <a href="https://wa.me/+59898979979" rel="noreferrer" target="_blank">
        <img alt="Icono de Whatsapp" src={WhatsappIcon} />
      </a>
      <a href="mailto:hola@m24.com.uy" rel="noreferrer" target="_blank">
        <img alt="Icono de Email" src={MailIcon} />
      </a>
      <a
        href="https://www.youtube.com/channel/UCu-U3hwhBTR3lzyZnPUL2Hg"
        rel="noreferrer"
        target="_blank"
      >
        <img alt="Icono de Youtube" src={YoutubeIcon} />
      </a>
    </StyledSocialNetworkContainer>
  );
};

export default SocialNetworks;
