import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import FacebookIcon from '../../images/compartir-facebook.png';
import FacebookWhiteIcon from '../../images/compartir-facebook-blanco.svg';
import TwitterIcon from '../../images/compartir-twitter.png';
import TwitterWhiteIcon from '../../images/compartir-twitter-blanco.svg';
import MailIcon from '../../images/compartir-mail.png';
import MailWhiteIcon from '../../images/compartir-mail-blanco.svg';
import WhatsappIcon from '../../images/compartir-whatsapp.png';
import WhatsappWhiteIcon from '../../images/compartir-whatsapp-blanco.svg';
import CerrarIcon from '../../images/cerrar.svg';
import { Post } from '../../types/types';

const StyledSocialMediaShareButtonsContainer = styled.div`
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: #00000069;
  display: flex;
  justify-content: center;
  align-items: center;
  .close-icon-container {
    position: absolute;
    transform: translate(5rem, -7rem);
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .social-icons-container {
    padding: 1rem 2rem 1rem 2rem;
    width: 10rem;
    height: 15rem;
    background-color: white;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border: 1px solid var(--border-gray);
    .title {
      margin-bottom: 1rem;
      font-family: 'TradeGothicN2';
      font-size: 1.5rem;
    }
    > div,
    a {
      display: flex;
      align-items: center;
      color: black;
      text-decoration: black;
      margin-bottom: 0.5rem;
      &.last-child {
        margin-right: 0;
      }
      &.fb-share-button {
        margin-bottom: 0;
      }
    }
    .social-icon {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
    }
  }
`;

type SocialMediaShareButtonsProps = {
  post: Post;
  displayed: boolean;
  toggleDisplayed: Dispatch<SetStateAction<boolean>>;
};

type CommonSocialNetworksProps = {
  noText?: boolean;
  title?: string;
  slug?: string;
  blanco?: boolean;
};

const FacebookShareButton = ({
  slug,
  noText = false,
  blanco = false,
}: CommonSocialNetworksProps) => (
  <div
    className="fb-share-button"
    data-href="https://www.m24.com.uy/"
    data-layout="button"
    data-size="large"
  >
    <a
      className="fb-xfbml-parse-ignore"
      href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.m24.com.uy%2F${slug}%2F&amp;src=sdkpreparse`}
      rel="noreferrer"
      target="_blank"
    >
      {!blanco ? (
        <img alt="Icono de Facebook" className="social-icon" src={FacebookIcon} />
      ) : (
        <img alt="Icono de Facebook" className="social-icon" src={FacebookWhiteIcon} />
      )}

      {!noText ? 'Facebook' : ''}
    </a>
  </div>
);

const TwitterShareButton = ({
  title,
  slug,
  noText = false,
  blanco = false,
}: CommonSocialNetworksProps) => (
  <>
    <a
      className="twitter-share-button"
      data-show-count="false"
      href={`https://twitter.com/intent/tweet?text=${encodeURI(
        `${`${title ? title : ''} https://www.m24.com.uy/${slug}`}`,
      )}`}
      rel="noreferrer"
      target="_blank"
    >
      {!blanco ? (
        <img alt="Icono de Twitter" className="social-icon" src={TwitterIcon} />
      ) : (
        <img alt="Icono de Twitter" className="social-icon" src={TwitterWhiteIcon} />
      )}
      {!noText ? 'Twitter' : ''}
    </a>
  </>
);

const MailShareButton = ({
  noText = false,
  title,
  slug,
  blanco = false,
}: CommonSocialNetworksProps) => (
  <a
    href={`mailto:?&subject=Te comparto la noticia de M24 - La FM con identidad uruguaya - ${title}&body=${`${title} https://www.m24.com.uy/${slug}`}`}
  >
    {!blanco ? (
      <img alt="Icono de Mail" className="social-icon" src={MailIcon} />
    ) : (
      <img alt="Icono de Mail" className="social-icon" src={MailWhiteIcon} />
    )}
    {!noText ? 'Mail' : ''}
  </a>
);

const WhatsappShareButton = ({
  noText = false,
  title,
  slug,
  blanco = false,
}: CommonSocialNetworksProps) => (
  <a
    className="last-child"
    data-action="share/whatsapp/share"
    href={`whatsapp://send?text=${title} - M24 - https://www.m24.com.uy/${slug}`}
  >
    {!blanco ? (
      <img alt="Icono de Whatsapp" className="social-icon" src={WhatsappIcon} />
    ) : (
      <img alt="Icono de Whatsapp" className="social-icon" src={WhatsappWhiteIcon} />
    )}
    {!noText ? 'Whatsapp' : ''}
  </a>
);

const SocialMediaShareButtons = ({
  post,
  displayed,
  toggleDisplayed,
}: SocialMediaShareButtonsProps) => {
  return (
    <>
      {displayed && (
        <StyledSocialMediaShareButtonsContainer onClick={() => toggleDisplayed(false)}>
          <button className="close-icon-container" onClick={() => toggleDisplayed(false)}>
            <img alt="Icono de cerrar" src={CerrarIcon} />
          </button>
          <div
            className="social-icons-container"
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()}
          >
            <p className="title">Compartir</p>
            <FacebookShareButton slug={post.slug} />
            <TwitterShareButton slug={post.slug} title={post.title} />
            <MailShareButton slug={post.slug} title={post.title} />
            <WhatsappShareButton slug={post.slug} title={post.title} />
          </div>
        </StyledSocialMediaShareButtonsContainer>
      )}
    </>
  );
};

export { FacebookShareButton, TwitterShareButton, MailShareButton, WhatsappShareButton };
export default SocialMediaShareButtons;
