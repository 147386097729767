import React from 'react';
import ReactSlider from 'react-slider';
import styled from 'styled-components';

const StyledVolumeSlider = styled.div`
  height: 100%;
  font-family: 'Roboto';
  display: flex;
  flex-direction: column;
  align-items: center;
  .slider {
    width: 0.5rem;
    height: 100%;
    border-radius: 15px;
    background-color: transparent;
    @media (min-width: 576px) {
      width: 0.5rem;
    }
    .thumb {
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      background-color: black;
      transform: translate(-2px, 0px);
    }
    .track-0 {
      background: #fff;
      width: 0.5rem;
      border-radius: 15px;
    }
    .track-1 {
      background: #ffffff75;
      width: 0.5rem;
      border-radius: 15px;
    }
  }
  span {
    color: white;
    font-size: 0.85rem;
    margin-top: 0.5rem;
  }
`;

type VolumeSliderProps = {
  player: HTMLAudioElement;
};

const VolumeSlider = ({ player }: VolumeSliderProps) => {
  return (
    <StyledVolumeSlider>
      <ReactSlider
        invert
        pearling
        ariaLabel="Manejo del volumen"
        className="slider"
        defaultValue={player.volume * 100}
        max={100}
        orientation="vertical"
        trackClassName="track"
        onChange={(value) => {
          player.volume = value / 100;
        }}
      />
      <span>Volumen</span>
    </StyledVolumeSlider>
  );
};

export default VolumeSlider;
