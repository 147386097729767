import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import {
  BASQUETBOL,
  PROGRAMAS_MAP,
  RADIO_EN_VIVO,
  RADIO_MALDONADO,
  VIVO_URL,
} from '../../utils/constants';
import { getCustomPlayerTitle, getLiveProgram } from '../../utils/utilities';
import { Programa } from '../../types/types';
import CircularSpinner from '../utilities/CircularSpinner';
import EnVivoIcon from '../utilities/EnVivoIcon';
import playIcon from '../../images/play-blanco.svg';
import pauseIcon from '../../images/pausa.svg';
import upArrowIcon from '../../images/flecha-arriba.svg';
import { useAppSelector } from '../../types/hooks';

import ExpandedPlayer from './ExpandedPlayer';

const StyledOverlay = styled.div`
  transition: opacity 0.2s ease-out;
  background-color: ${(props: any) => (props.expanded ? '#03030357' : 'transparent')};
  opacity: ${(props: any) => (props.expanded ? '1' : '0')};
  position: fixed;
  width: 100%;
  height: 100vh;
  box-shadow: 0px -1rem 3rem 6rem #00000052;
  bottom: ${(props: any) => (props.expanded ? '0' : '-58rem')};
  left: 0;
  z-index: -1;
`;

const StyledPlayerContainer = styled.div`
  position: relative;
  left: 0;
  width: 100vw;
  background-color: transparent;
  font-family: 'NotoSans';
  min-height: 4rem;
  transition: bottom 0.2s ease-out;
  bottom: ${(props: any) => (props.expanded ? '21.7rem' : '-0.5rem')};
  @media (min-width: 576px) {
    bottom: ${(props: any) => (props.expanded ? '0rem' : '-4rem')};
    width: 60%;
  }
  .collapsed-player {
    position: absolute;
    width: 100%;
    top: -2.8rem;
    transition: all 0.2s ease-out;
    background-color: ${(props: any) =>
      props.isLive ? 'var(--rojo-principal)' : 'var(--verde-principal)'};
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    @media (min-width: 576px) {
      top: -4rem;
      border-top-right-radius: unset;
      border-top-left-radius: unset;
    }
    &.expanded {
      top: 0;
      opacity: 0;
    }
    .player-desktop-container {
      min-height: 2.9rem;
      display: grid;
      grid-template-columns: 3.5rem 4fr 1fr;
      border-top-right-radius: 0.5rem;
      border-top-left-radius: 0.5rem;
      @media (min-width: 576px) {
        grid-template-columns: 4rem 4fr 1fr;
        margin: 0 auto;
        max-width: 1100px;
        border-top-right-radius: unset;
        border-top-left-radius: unset;
      }
      .audio-player-container {
        display: flex;
        justify-content: center;
        @media (min-width: 576px) {
          justify-content: flex-start;
        }
        button {
          background-color: transparent;
          border: none;
          width: 3rem;
          @media (min-width: 576px) {
            height: 2.7rem;
          }
        }
        .play-icon {
          img {
            width: 1.9rem;
            height: 1.9rem;
          }
        }
      }
      .title-description-container {
        font-family: 'TradeGothic Gold18';
        color: white;
        display: flex;
        align-items: center;
        @media (min-width: 576px) {
          height: 2.6rem;
        }
        .icono-vivo-podcast {
          width: 4rem;
          height: 1rem;
          margin-right: 0.5rem;
        }
        .program-name {
          text-transform: uppercase;
          font-size: 1.1rem;
          margin-left: 0.5rem;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 52vw;
          white-space: nowrap;
          font-family: 'TradeGothicN2';
          background: transparent;
          color: white;
          text-align: left;
          padding: 0;
          font-weight: 800;
          @media (min-width: 576px) {
            font-size: 1.25rem;
            width: 24rem;
          }
        }
        p {
          font-size: 0.9rem;
          color: var(--rojo-principal);
          background-color: white;
          border-radius: 4px;
          width: 4rem;
          text-transform: uppercase;
          text-align: center;
          padding: 0.3rem 0 0;
          margin-right: 1rem;
        }
      }
      .open-player-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        button {
          background-color: transparent;
          border: none;
          width: 3rem;
          height: 2rem;
          margin-right: 0.5rem;
          @media (min-width: 576px) {
            margin-right: 0;
          }
          img {
            width: 1.2rem;
            height: 1.2rem;
            @media (min-width: 576px) {
              width: 1.5rem;
              height: 1.5rem;
            }
          }
        }
      }
    }
  }
`;

type PlayerProps = {
  expandedPlayer: boolean;
  setExpandedPlayer: Dispatch<SetStateAction<boolean>>;
  isFooterBtnClicked?: boolean;
  playerUrl: string;
  timeStamp?: number;
  playerSlug: string;
  podcastTitle: string;
  podcastSlug: string;
  isLive: boolean;
};

const Player = ({
  expandedPlayer,
  setExpandedPlayer,
  isFooterBtnClicked,
  playerUrl,
  timeStamp,
  playerSlug,
  podcastTitle,
  podcastSlug,
  isLive,
}: PlayerProps) => {
  const player = useRef<HTMLAudioElement>(null);
  const [audioStatus, setAudioStatus] = useState<string>('');
  const [program, setProgram] = useState<Programa>(RADIO_EN_VIVO);
  const { titulosPersonalizadosReproductor } = useAppSelector(({ app }) => ({
    titulosPersonalizadosReproductor: app.titulosPersonalizadosReproductor,
  }));

  useEffect(
    function listenToAudioURLChange() {
      if (
        player.current &&
        (audioStatus !== '' ||
          (audioStatus === '' && isFooterBtnClicked) ||
          (!isFooterBtnClicked && playerUrl !== VIVO_URL))
      ) {
        player.current.setAttribute('src', playerUrl !== VIVO_URL ? playerUrl : VIVO_URL);
        player.current.volume = playerUrl !== VIVO_URL ? 0.8 : 1;
        player.current.play().catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
      }
    },
    [playerUrl, isFooterBtnClicked, timeStamp],
  );

  useEffect(() => {
    if (titulosPersonalizadosReproductor && titulosPersonalizadosReproductor.length > 0) {
      const customTitle = getCustomPlayerTitle(titulosPersonalizadosReproductor);

      if (customTitle) {
        setProgram(customTitle);

        return;
      }
    }

    if (playerSlug === RADIO_MALDONADO.slug) {
      setProgram(RADIO_MALDONADO);
    } else if (playerSlug === BASQUETBOL.slug) {
      setProgram(BASQUETBOL);
    } else {
      const program =
        playerSlug === RADIO_EN_VIVO.slug ? getLiveProgram(new Date()) : PROGRAMAS_MAP[playerSlug];

      setProgram(!program ? RADIO_EN_VIVO : program);
    }
  }, [playerSlug, titulosPersonalizadosReproductor]);

  const playAudio = () => {
    player.current?.play();
  };

  const pauseAudio = () => {
    player.current?.pause();
  };

  const movePlayer = (time: number) => {
    if (!player.current) return;
    player.current.currentTime += time;
  };

  return (
    <>
      <StyledPlayerContainer expanded={expandedPlayer} isLive={isLive}>
        <div onClick={() => setExpandedPlayer(false)}>
          <StyledOverlay expanded={expandedPlayer} />
        </div>
        <div className={expandedPlayer ? 'collapsed-player expanded' : 'collapsed-player'}>
          <div className="player-desktop-container">
            <div className="audio-player-container">
              <audio
                ref={player}
                src={VIVO_URL}
                onPause={() => setAudioStatus('pausing')}
                onPlaying={() => setAudioStatus('playing')}
                onWaiting={() => setAudioStatus('waiting')}
              />
              {audioStatus === 'playing' ? (
                <button className="play-icon" onClick={pauseAudio}>
                  <img alt="Pause button" src={pauseIcon} />
                </button>
              ) : audioStatus === 'waiting' ? (
                <CircularSpinner height={2} margin="0.5rem 0rem" width={2} />
              ) : (
                <button className="play-icon" onClick={playAudio}>
                  <img alt="Play button" src={playIcon} />
                </button>
              )}
            </div>
            <div className="title-description-container">
              {isLive ? (
                <EnVivoIcon
                  height="1rem"
                  isPlaying={audioStatus === 'playing'}
                  left="0"
                  top="0"
                  width="3rem"
                />
              ) : (
                <EnVivoIcon
                  isPodcast
                  height="2rem"
                  isPlaying={audioStatus === 'playing'}
                  left="0"
                  top="0"
                  width="4rem"
                />
              )}
              <p className="program-name" title={program.title}>
                {program.title}
              </p>
            </div>
            <div className="open-player-container">
              <button onClick={() => setExpandedPlayer(true)}>
                <img alt="Icono ampliar reproductor" src={upArrowIcon} />
              </button>
            </div>
          </div>
        </div>
        {player.current && (
          <ExpandedPlayer
            audioStatus={audioStatus}
            handleClose={() => setExpandedPlayer(false)}
            isExpanded={expandedPlayer}
            isLiveOn={isLive}
            on1less={() => movePlayer(-1)}
            on1more={() => movePlayer(1)}
            on5less={() => movePlayer(-5)}
            on5more={() => movePlayer(5)}
            player={player.current}
            podcastSlug={podcastSlug}
            podcastTitle={podcastTitle}
            program={program}
            onPause={pauseAudio}
            onPlay={playAudio}
          />
        )}
      </StyledPlayerContainer>
    </>
  );
};

export default Player;
