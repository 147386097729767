import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CustomHorario, SpecialTransmitionHours } from '../types/types';
import { PROGRAMAS_MAP, VIVO_URL } from '../utils/constants';

interface AppState {
  playerInfo: {
    playerUrl: string;
    playerSlug: string;
    isFooterBtnClicked?: boolean;
    timeStamp?: number;
  };
  podcastInfo: {
    podcastTitle: string;
    podcastSlug: string;
  };
  isMenuOpen: boolean;
  isSearchBoxVisible: boolean;
  titulosPersonalizadosReproductor: CustomHorario[] | undefined;
  basquetbolHours: SpecialTransmitionHours | undefined;
  carnavalHours: SpecialTransmitionHours | undefined;
}

const initialState: AppState = {
  playerInfo: {
    playerUrl: VIVO_URL,
    playerSlug: PROGRAMAS_MAP['radio-en-vivo'].slug,
    isFooterBtnClicked: false,
    timeStamp: new Date().getMilliseconds(),
  },
  podcastInfo: {
    podcastTitle: '',
    podcastSlug: '',
  },
  isMenuOpen: false,
  isSearchBoxVisible: false,
  titulosPersonalizadosReproductor: undefined,
  basquetbolHours: undefined,
  carnavalHours: undefined,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    changePlayerInfo(state: AppState, action: PayloadAction<AppState['playerInfo']>) {
      state.playerInfo = action.payload;
    },
    changePodcastInfo(state: AppState, action: PayloadAction<AppState['podcastInfo']>) {
      state.podcastInfo = action.payload;
    },
    setIsMenuOpen(state: AppState, action: PayloadAction<boolean>) {
      state.isMenuOpen = action.payload;
    },
    setIsSearchBoxVisible(state: AppState, action: PayloadAction<boolean>) {
      state.isSearchBoxVisible = action.payload;
    },
    setTitulosPersonalizadosReproductor(state: AppState, action: PayloadAction<string>) {
      if (action.payload) {
        try {
          state.titulosPersonalizadosReproductor = JSON.parse(action.payload);
        } catch (error) {
          console.log('⚠️ Error while parsing titulos personalizados del reproductor');
          console.log('⚠️ Verificar configuración en Wordpress');
        }
      }
    },
    setBasquetbolHours(state: AppState, action: PayloadAction<string>) {
      if (action.payload) {
        try {
          state.basquetbolHours = JSON.parse(action.payload);
        } catch (error) {
          console.log('⚠️ Error while parsing basquetbol hours');
          console.log('⚠️ Verificar configuración en Wordpress');
        }
      }
    },
    setCarnavalHours(state: AppState, action: PayloadAction<string>) {
      if (!action.payload) return;
      try {
        state.carnavalHours = JSON.parse(action.payload);
      } catch (error) {
        console.log('⚠️ Error while parsing basquetbol hours');
        console.log('⚠️ Verificar configuración en Wordpress');
      }
    },
  },
});

export const {
  changePlayerInfo,
  setIsMenuOpen,
  setIsSearchBoxVisible,
  changePodcastInfo,
  setTitulosPersonalizadosReproductor,
  setBasquetbolHours,
  setCarnavalHours,
} = appSlice.actions;

export default appSlice.reducer;
