import React, { ChangeEvent, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useState } from 'react';

import Spinner from '../utilities/Spinner';
import { useAppDispatch } from '../../types/hooks';
import { setIsSearchBoxVisible } from '../../slices/AppSlice';
import LupaIcon from '../../images/lupa-blanca.svg';
import CerrarIcon from '../../images/cerrar.svg';

const StyledSearchBoxContainer = styled.div`
  position: fixed;
  z-index: 3;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-color: #00000069;
  display: flex;
  justify-content: center;
  align-items: ${(props: any) => (props.hasItems ? 'flex-start' : 'center')};
  @media (min-width: 576px) {
    align-items: ${(props: any) => (props.hasItems ? 'flex-start' : 'center')};
    padding-top: 2rem;
  }
  @media (min-width: 1441px) {
    align-items: center;
    padding-top: 0;
  }
  .search-box-itself {
    width: 92%;
    margin: 1rem auto 0;
    min-height: 10rem;
    background-color: #f6f6f6;
    border-radius: 0.5rem;
    border: 1px solid gray;
    position: relative;
    @media (min-width: 576px) {
      width: 50%;
      margin: 0 auto;
    }
    .close-btn-container {
      display: flex;
      justify-content: flex-end;
      margin: 1rem 1rem 0 0;
      button {
        img {
          width: 1.2rem;
          height: 1.2rem;
        }
      }
    }
    form {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;
      input {
        height: 3rem;
        border-radius: 0.55rem;
        border: 1px solid gray;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
        box-sizing: border-box;
        padding: 1rem;
        font-size: 1rem;
        font-family: 'Roboto Light';
        width: 15rem;
        @media (min-width: 576px) {
          padding: 1rem 1.5rem;
          font-size: 1.2rem;
          width: 33rem;
        }
        &:focus {
          outline: none;
        }
      }
      button[type='submit'] {
        background-color: var(--rojo-principal);
        border-top-right-radius: 0.55rem;
        border-bottom-right-radius: 0.55rem;
        height: 3rem;
        border: 1px solid transparent;
        border-left: none;
        img {
          width: 1.5rem;
          height: 1.5rem;
          padding: 0.25rem 0.5rem;
        }
      }
    }
    .search-result {
      min-height: 10rem;
      width: 17rem;
      padding-top: 1rem;
      margin: 0 auto;
      @media (min-width: 576px) {
        width: 35rem;
      }
      h3 {
        margin-bottom: 0.5rem;
        @media (min-width: 576px) {
          margin-bottom: 1rem;
        }
      }
      .results-items-container {
        min-height: 23rem;
        max-height: 26rem;
        overflow-y: auto;
        .result-item {
          margin-bottom: 0.5rem;
          padding-bottom: 0.5rem;
          border-bottom: 1px solid var(--border-gray);
          a {
            font-family: 'TradeGothic Gold18';
            color: black;
            text-decoration: none;
            cursor: pointer;
            font-size: 1rem;
            @media (min-width: 576px) {
              font-size: 18px;
            }
          }
        }
      }
      .pagination {
        margin: 1rem 0;
        display: flex;
        justify-content: center;
        @media (min-width: 576px) {
          margin-top: 2rem;
        }
        ol {
          display: flex;
          li {
            list-style: none;
            margin-right: 0.5rem;
            cursor: pointer;
            &.red {
              color: var(--rojo-principal);
            }
          }
        }
      }
    }
  }
`;

type SearchResultItem = {
  id: number;
  subtype: string;
  title: string;
  type: string;
  url: string;
};

type SearchResultPage = SearchResultItem[];

const SearchBox = () => {
  const dispatch = useAppDispatch();
  const [searchInput, setSearchInput] = useState<{ value: string }>({
    value: '',
  });
  const [results, setResults] = useState<SearchResultPage[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);

  useEffect(() => {
    return () => {
      dispatch(setIsSearchBoxVisible(false));
    };
  }, [dispatch]);

  const searchTerm = async (event: any) => {
    event.preventDefault();
    setResults([]);
    setCurrentPage(0);
    if (searchInput.value.length > 3) {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${process.env.GATSBY_HOST_URL}wp-json/wp/v2/search?search=${searchInput.value}&per_page=100&context=embed`,
        );

        if (response.data.length === 0) return;

        const allResultsArray = [];
        let pagArray = [];

        const newSortedResults = response.data.sort((a: any, b: any) => b.id - a.id);

        for (let i: number = 0; i < newSortedResults.length; i++) {
          if (i % 5 === 0) {
            pagArray = [];
          } else if (i % 5 === 4) {
            allResultsArray.push(pagArray);
          }
          const textAreaAuxEle = document.createElement('textarea');

          textAreaAuxEle.innerHTML = newSortedResults[i].title;

          const newSearchResultItem = {
            ...newSortedResults[i],
            url: newSortedResults[i].url.replace(process.env.GATSBY_HOST_URL, '/'),
            title: textAreaAuxEle.value,
          };

          pagArray.push(newSearchResultItem);
        }
        if (pagArray.length > 0) {
          allResultsArray.push(pagArray);
        }
        setResults(allResultsArray);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <StyledSearchBoxContainer
      hasItems={results.length > 0}
      onClick={() => dispatch(setIsSearchBoxVisible(false))}
    >
      <div className="search-box-itself" onClick={(event) => event.stopPropagation()}>
        <div className="close-btn-container">
          <button onClick={() => dispatch(setIsSearchBoxVisible(false))}>
            <img alt="Icono de cerrar el dialogo de busqueda" src={CerrarIcon} />
          </button>
        </div>
        <form onSubmit={searchTerm}>
          <input
            name="searchterm"
            placeholder="Buscar noticias, podcasts..."
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSearchInput({ value: e.target.value })
            }
          />
          <button type="submit">
            <img alt="Icono de lupa blanca" src={LupaIcon} />
          </button>
        </form>
        {isLoading && <Spinner height="2rem" width="2rem" />}
        {results.length > 0 && (
          <div className="search-result">
            <h3>Resultados:</h3>
            <div className="results-items-container">
              {results[currentPage].map((result: SearchResultItem) => (
                <div key={result.id} className="result-item">
                  <a aria-label={result.title} href={result.url}>
                    {result.title}
                  </a>
                </div>
              ))}
            </div>
            <div className="pagination">
              <ol>
                {currentPage !== 0 && (
                  <li className="red" onClick={() => setCurrentPage(currentPage - 1)}>
                    Anterior
                  </li>
                )}
                {results.map((page: SearchResultItem[], index: number) => {
                  return (
                    index < currentPage + 5 &&
                    index > currentPage - 5 && (
                      <li
                        key={index}
                        className={currentPage === index ? 'red' : ''}
                        onClick={() => setCurrentPage(index)}
                      >
                        {index + 1}
                      </li>
                    )
                  );
                })}
                {currentPage !== results.length - 1 && (
                  <li className="red" onClick={() => setCurrentPage(currentPage + 1)}>
                    Siguiente
                  </li>
                )}
              </ol>
            </div>
          </div>
        )}
      </div>
    </StyledSearchBoxContainer>
  );
};

export default SearchBox;
