import React, { useEffect, useState } from 'react';
import ReactSlider from 'react-slider';
import styled from 'styled-components';

import useInterval from '../../hooks/useInterval';

const StyledSeekSlider = styled.div`
  z-index: 1;
  @media (min-width: 576px) {
    position: absolute;
    transform: translate(25.2rem, 10.5rem);
  }
  .remaining-time,
  .total-time {
    position: absolute;
    color: white;
    font-family: 'Roboto Light';
    font-size: 0.7rem;
  }
  .remaining-time {
    left: 0.5rem;
  }
  .total-time {
    right: 0.5rem;
  }
  .slider {
    width: 100vw;
    height: 1rem;
    @media (min-width: 576px) {
      width: 16rem;
    }
    .thumb {
      width: 1rem;
      height: 1rem;
      background-color: black;
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      top: -0.3rem;
    }
    .track {
      background-color: #d5d5d58c;
      height: 0.4rem;
      &.track-1 {
        background-color: #d5d5d5;
      }
    }
  }
`;

type SeekSliderProps = {
  currentValue?: number;
  isPlaying: boolean;
  player: HTMLAudioElement;
};

const SeekSlider = ({ isPlaying, player }: SeekSliderProps) => {
  const [value, setValue] = useState<number>(0);
  const [duration, setDuration] = useState<string>('');

  const getMinutesAndSeconds = (totalTimeInSeconds: number) => {
    const minutes = Math.floor((totalTimeInSeconds % (24 * 3600 * 3600)) / 60);
    const seconds = totalTimeInSeconds % 60;

    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  useEffect(() => {
    if (!isNaN(player.duration))
      setDuration(`${getMinutesAndSeconds(Math.floor(player.duration))}`);
  }, [player.duration]);

  useInterval(
    () => {
      setValue(player.currentTime);
    },
    isPlaying ? 1000 : null,
  );

  return (
    <StyledSeekSlider>
      {!isNaN(player.duration) && (
        <ReactSlider
          className="slider"
          max={player.duration}
          thumbClassName="thumb"
          trackClassName="track"
          value={value}
          onChange={(va) => {
            setValue(va);
            player.currentTime = va;
          }}
        />
      )}
      <span className="remaining-time">{getMinutesAndSeconds(Math.floor(value))}</span>
      <span className="total-time">{duration}</span>
    </StyledSeekSlider>
  );
};

export default SeekSlider;
