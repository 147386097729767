import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { ECONOMIA, FUTBOL, MUNDO, POLITICA, SOCIEDAD } from '../../utils/constants';

const StyledDesktopNavBarContainer = styled.nav`
  display: none;
  background-color: #f4f4f4;
  position: relative;

  @media (min-width: 576px) {
    display: block;
  }

  ul {
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    li {
      list-style: none;
      font-family: 'TradeGothicN2';
      font-size: 0.8rem;
      padding: 0.25rem 0;
      margin-right: 0.5rem;

      a {
        color: black;
        text-decoration: none;
        text-transform: uppercase;
        padding-right: 0.25rem;
        padding-left: 0.25rem;
      }

      &:hover {
        background-color: #d2d1d1;
      }
    }
  }
`;

const menuItems = [POLITICA, SOCIEDAD, ECONOMIA, MUNDO, FUTBOL];

const DesktopNavBar = () => {
  return (
    <StyledDesktopNavBarContainer>
      <ul>
        {menuItems.map((item) => (
          <li key={item.slug}>
            <Link to={`/${item.slug}`}>{item.title}</Link>
          </li>
        ))}
      </ul>
    </StyledDesktopNavBarContainer>
  );
};

export default DesktopNavBar;
