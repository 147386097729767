import React from 'react';
import styled, { keyframes } from 'styled-components';

const ldsRing = keyframes`
  0% {
      transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledCircularSpinnerConatiner = styled.div`
  display: inline-block;
  position: relative;
  width: ${(props: any) => `${props.width}rem`};
  height: ${(props: any) => `${props.height}rem`};
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${(props: any) => `${props.width - 0.2}rem`};
    height: ${(props: any) => `${props.height - 0.2}rem`};
    margin: ${(props: any) => props.margin};
    border: ${(props: any) => `0.3rem solid ${props.color}`};
    border-radius: 50%;
    animation: ${ldsRing} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props: any) => `${props.color} transparent transparent transparent`};
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

type CircularSpinnerProps = {
  width: number;
  height: number;
  margin: string;
  color?: string;
};

const CircularSpinner = ({ width, height, margin, color = '#fff' }: CircularSpinnerProps) => {
  return (
    <StyledCircularSpinnerConatiner color={color} height={height} margin={margin} width={width}>
      <div />
      <div />
      <div />
      <div />
    </StyledCircularSpinnerConatiner>
  );
};

export default CircularSpinner;
