import React from 'react';
import styled, { keyframes } from 'styled-components';

const skBounce = keyframes`
    0%, 100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
`;

const StyledSipinnerContainer = styled.div`
  width: ${(props: any) => props.width};
  height: ${(props: any) => props.height};

  position: relative;
  margin: 100px auto;

  .double-bounce1,
  .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #333;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: ${skBounce} 2s infinite ease-in-out;
    animation: ${skBounce} 2s infinite ease-in-out;
  }

  .double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
`;

interface SpinnerProps {
  width: string;
  height: string;
}

const Spinner = ({ width, height }: SpinnerProps) => {
  return (
    <StyledSipinnerContainer height={height} width={width}>
      <div className="double-bounce1" />
      <div className="double-bounce2" />
    </StyledSipinnerContainer>
  );
};

export default Spinner;
