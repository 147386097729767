import axios from 'axios';

import {
  Category,
  CustomHorario,
  Event,
  Post,
  Programa,
  SpecialTransmitionHours,
} from '../types/types';

import { PROGRAMACION, PROGRAMAS_MAP, RADIO_EN_VIVO } from './constants';

const dias = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
const meses = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Setiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

export const getMonth = (date: Date) => {
  return meses[date.getMonth()];
};

export const getUyFormattedDate = (date: Date, noDayWord: boolean = true) => {
  const day = date.getDay();
  const dateNumber = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  if (noDayWord) {
    return `${dateNumber < 10 ? `0${dateNumber}` : dateNumber} de ${meses[month]} de ${year}`;
  }

  return `${dias[day]} ${dateNumber < 10 ? `0${dateNumber}` : dateNumber} de ${
    meses[month]
  } de ${year}`;
};

export const getHour = (date: Date) => {
  const hour = date.getHours();
  const minutes = date.getMinutes();

  if (minutes !== 0) {
    return `${hour}:${minutes.toString().length === 1 ? `0${minutes}` : minutes} horas`;
  }

  return `${hour} horas`;
};

export const parseDate = (dateStr: string, widthoutTime: boolean = false) => {
  const [date, hour] = dateStr.split(' ');
  const [year, month, day] = date.split('-');
  const [hours, minutes, seconds] = hour.split(':');

  return new Date(
    parseInt(year),
    parseInt(month) - 1,
    parseInt(day),
    widthoutTime ? 0 : parseInt(hours),
    widthoutTime ? 0 : parseInt(minutes),
    widthoutTime ? 0 : parseInt(seconds),
  );
};

export const getMainCategorySlug = (categories: Category[]) => {
  return categories.reduce((mainCategory: string, category: Category) => {
    return Object.keys(PROGRAMAS_MAP).includes(category.slug) ? category.slug : mainCategory;
  }, '');
};

export const copyToClipboard = (
  text: string,
  successCallback: () => void,
  failedCallback: () => void,
) => {
  navigator.clipboard.writeText(text).then(successCallback, failedCallback);
};

export const getLiveProgram = (date: Date) => {
  const day = date.getDay();
  const hour = date.getHours();
  const minutes = date.getMinutes();

  const dayPrograms = PROGRAMACION[day];
  let programSlug = RADIO_EN_VIVO.slug;

  Object.keys(dayPrograms).forEach((startEndHour: string) => {
    const [startHour, startMinutes] = startEndHour.split('-')[0].split(':');
    const [endHour, endMinutes] = startEndHour.split('-')[1].split(':');

    if (
      hour >= parseInt(startHour) &&
      minutes >= parseInt(startMinutes) &&
      hour <= parseInt(endHour) &&
      minutes < parseInt(endMinutes)
    ) {
      programSlug = dayPrograms[startEndHour];
    }
  });

  return PROGRAMAS_MAP[programSlug];
};

// dateString needs to come in the following way: 18:00-21:00
const isNowBetweenTwoHoursRange = (dateString: string) => {
  // Check formt of dateString
  if (!/\d{2}:\d{2}-\d{2}:\d{2}/.test(dateString)) return;

  const now = new Date();

  const [startHour, startMinutes] = dateString.split('-')[0].split(':');
  const [endHour, endMinutes] = dateString.split('-')[1].split(':');
  const startDate = new Date();

  startDate.setHours(parseInt(startHour));
  startDate.setMinutes(parseInt(startMinutes));
  const endDate = new Date();

  endDate.setHours(parseInt(endHour));
  endDate.setMinutes(parseInt(endMinutes));

  return startDate <= now && now < endDate;
};

export const getCustomPlayerTitle: (
  titulosPersonalizados: CustomHorario[],
) => Programa | undefined = (titulosPersonalizados) => {
  const date = new Date();
  const day = date.getDay();
  let customTitle;

  titulosPersonalizados.forEach((custom_horario: CustomHorario) => {
    if (day === custom_horario.dia && isNowBetweenTwoHoursRange(custom_horario.horario)) {
      customTitle = {
        title: custom_horario.titulo,
        conducen: '',
        horario: '',
        slug: RADIO_EN_VIVO.slug,
      };
    }
  });

  return customTitle;
};

export const shouldSpecialTransmitionBeDisplayed: (
  hours: SpecialTransmitionHours | undefined,
  date?: Date,
) => boolean = (hours, date = new Date()) => {
  let isDisplayed: boolean = false;
  const mapDays: { [day: number]: string } = {
    0: 'domingo',
    1: 'lunes',
    2: 'martes',
    3: 'miercoles',
    4: 'jueves',
    5: 'viernes',
    6: 'sabado',
  };
  const today = mapDays[date.getDay()];

  if (hours && hours[today]) {
    const differentHours = hours[today].split('|');

    isDisplayed = differentHours.some((hour) => isNowBetweenTwoHoursRange(hour));
  }

  return isDisplayed;
};

export const downloadAudio = async ({
  url,
  name,
  fetching,
  setFetching,
}: {
  url: string;
  name: string;
  fetching: boolean;
  setFetching: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  if (!url && !fetching) return;
  setFetching(true);
  try {
    const response = await fetch(url);

    const blob = await response.blob();
    const blobURL = URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.href = blobURL;
    a.style.cssText = 'display: none';
    a.download = name;
    document.body.appendChild(a);
    a.click();
  } catch (error) {
    console.log(error);
  } finally {
    setFetching(false);
  }
};

export const getPosts = async (
  categoryId: number | number[],
  page: number = 1,
  postPerPage: number = 20,
) => {
  const categoryString = typeof categoryId === 'object' ? categoryId.join(',') : categoryId;

  try {
    const response = await axios.get(
      `${process.env.GATSBY_HOST_URL}wp-json/wp/v2/posts?per_page=${postPerPage}&categories=${categoryString}&page=${page}&order=desc&_embed`,
    );

    return {
      data: response.data,
      totalPages: parseInt(response.headers['x-wp-totalpages']),
    };
  } catch (exception) {
    console.log(exception);

    return;
  }
};

export const buildWordpressRestApiPost: (posts: any[], programa?: Programa) => Post[] = (
  posts,
  programa,
) => {
  return posts.map((item: any) => {
    const tags: { name: string; slug: string }[] = [];

    if (item.manual_tags) {
      item.manual_tags.split('|').forEach((tag: string) => {
        if (tag) {
          const [slug, name] = tag.split(',');

          tags.push({ slug, name });
        }
      });
    }

    let categories: { nodes: Category[] } = { nodes: [] };

    if (programa) {
      categories = {
        nodes: [{ name: programa.title, slug: programa.slug, parentId: '' }],
      };
    }

    return {
      id: String(item.id),
      wordpressImage:
        item?._embedded && item?._embedded['wp:featuredmedia'].length > 0
          ? item?._embedded['wp:featuredmedia'][0].media_details?.sizes?.medium?.source_url
          : '',
      slug: String(item.slug),
      title: String(item.title.rendered)
        .replace(/&#8211;/g, '–')
        .replace(/&#8220;/g, '“')
        .replace(/&#8221;/g, '”'),
      tags: {
        nodes: tags,
      },
      categories,
      afc_post: {
        audioDeLaNota: {
          mediaItemUrl: String(item.acf.audio_de_la_nota),
        },
        nombreColumnista: item.acf.nombre_columnista ?? item.acf.nombre_columnista,
        nombreFotografo: '',
      },
      excerpt: '',
      link: '',
      date: '',
    };
  });
};

export const buildWordpressRestApiEvents: (wpEvents: any[]) => Event[] = (wpEvents) => {
  return wpEvents.map((item: any) => ({
    databaseId: item.id,
    title: item.title.rendered,
    content: item.content.rendered,
    slug: item.slug,
    wordpressImageUrl:
      item?._embedded['wp:featuredmedia'].length > 0
        ? item?._embedded['wp:featuredmedia'][0].media_details?.sizes?.medium?.source_url
        : '',
    eventInfo: {
      categoria: item.acf.categoria,
      fecha: item.acf.fecha,
      tickets: item.acf.tickets,
      venue: item.acf.venue,
      artista: item.acf.artista,
      eventoDestacado: item.acf.evento_destacado,
    },
  }));
};
